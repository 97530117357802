import React from "react";
import { livebinding } from "../../data/LiveBinding.json";
import Layout from "../Helpers/Layout";
import TopCollection from "../HomeOne/TopCollection";
import ExploreProduct from "./ExploreProduct";
import Hero from "./Hero";
import NewsItem from "./NewsItem";
import TopSeller from "./TopSeller";

function HomeThree() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Hero />
        <NewsItem datas={livebindingData} />
        <TopSeller />
        <ExploreProduct datas={livebindingData} />
        <TopCollection />
      </Layout>
    </>
  );
}

export default HomeThree;
