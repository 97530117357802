import React, { useState } from "react";
import useToggle from "../../hooks/useToggle";

function SelectBox({ datas = [], action, title }) {
  const [item, setItem] = useState(datas[0]);
  // custom hook
  const [toggle, setToggle] = useToggle(false);
  const handler = (e, value) => {
    e.preventDefault();
    if (action) {
      action(value);
    }
    setItem(value);
    setToggle.set(false);
  };
  return (
    <>
      {datas.length > 0 && (
        <div className="filter-item-box">
          <span className="filter-item-heading">{title}</span>
          <div
            className="filter-item-current d-flex justify-content-between align-items-center"
            onClick={setToggle.toggle}
          >
            <span className="">{item}</span>
            <span className="filter-item-list-icon">
              <i className="fas fa-angle-down"></i>
            </span>
          </div>
          <div
            id="filter-item-list-dropdown-1"
            className={`filter-item-list-dropdown ${toggle ? "active" : ""}`}
          >
            <ul>
              {datas.map((selectItem) => (
                <li key={Math.random()}>
                  <a onClick={(e) => handler(e, selectItem)} href="#">
                    {selectItem}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectBox;
