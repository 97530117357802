import React from "react";

function Testimonial() {
  return (
    <section className="off-black-bg">
      <div className="container">
        <div className="row">
          <div
            className="section-title-box text-center mb-60 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <p className="sub-title">Testimonial</p>
            <h3 className="section-title">What Our Client Feedback</h3>
          </div>
        </div>
        <div className="row">
          <div className="client-feedback-lists d-flex justify-content-between align-items-center">
            <div
              className="single-client-feedback-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="single-client-feedback-inner">
                <div className="client-author-info-box d-flex align-items-center">
                  <div className="client-author-img ceo-img">
                    <img
                      src={require(`../../assets/img/seller-05.png`).default}
                      alt=""
                    />
                  </div>
                  <div className="client-author-name-box">
                    <h4 className="client-author-name">Balaji Srinivisan</h4>
                    <span className="client-author-desig">CEO Of Beatplan</span>
                  </div>
                  <div className="client-feedback-icon">
                    <img
                      src={
                        require(`../../assets/img/client-feedback-icon.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="client-feedback-description">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in so
                    me form, by injected humour, or randomised words
                  </p>
                </div>
              </div>
            </div>
            <div
              className="single-client-feedback-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="single-client-feedback-inner">
                <div className="client-author-info-box d-flex align-items-center">
                  <div className="client-author-img ceo-img">
                    <img
                      src={require(`../../assets/img/seller-05.png`).default}
                      alt=""
                    />
                  </div>
                  <div className="client-author-name-box">
                    <h4 className="client-author-name">Balaji Srinivisan</h4>
                    <span className="client-author-desig">CEO Of Beatplan</span>
                  </div>
                  <div className="client-feedback-icon">
                    <img
                      src={
                        require(`../../assets/img/client-feedback-icon.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="client-feedback-description">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in so
                    me form, by injected humour, or randomised words
                  </p>
                </div>
              </div>
            </div>
            <div
              className="single-client-feedback-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="single-client-feedback-inner">
                <div className="client-author-info-box d-flex align-items-center">
                  <div className="client-author-img ceo-img">
                    <img
                      src={require(`../../assets/img/seller-05.png`).default}
                      alt=""
                    />
                  </div>
                  <div className="client-author-name-box">
                    <h4 className="client-author-name">Balaji Srinivisan</h4>
                    <span className="client-author-desig">CEO Of Beatplan</span>
                  </div>
                  <div className="client-feedback-icon">
                    <img
                      src={
                        require(`../../assets/img/client-feedback-icon.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="client-feedback-description">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in so
                    me form, by injected humour, or randomised words
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
