import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import AreaMap from "./AreaMap";
import MainSection from "./MainSection";

function Contact() {
  return (
    <>
      <Layout>
        <Breadcrumb sectionTitle="Contact" sectionName="Contact" />
        <MainSection />
        <div style={{ overflowX: "hidden" }}>
          <AreaMap mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60948.76159195912!2d-74.06731519232034!3d40.73030864720143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25090129c363d%3A0x40c6a5770d25022b!2sStatue%20of%20Liberty!5e0!3m2!1sen!2sbd!4v1647323195212!5m2!1sen!2sbd" />
        </div>
      </Layout>
    </>
  );
}

export default Contact;
