import React from "react";

function FooterHomeOne({ className }) {
  return (
    <footer className={`nft-footer ${className || ""}`}>
      <section className="off-black-bg">
        <div className="container">
          <div
            className="row footer-list justify-content-between wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div className="footer-widget">
              <div className="footer-logo">
                <img
                  src={require(`../../../assets/img/logo.png`).default}
                  alt=""
                />
              </div>
              <p>
                There are many variations of passages of but the majority have
                suffered alterations cted humour, or randomsed words which htly
                believable. If you are going
              </p>
              <h6 className="footer-social-icon-title">Join the community</h6>
              <div className="footer-social-icon-list">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-slack"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>

            <div className="footer-widget">
              <h4 className="widget-title">Marketplace</h4>
              <ul>
                <li>
                  <a href="#">Gaming</a>
                </li>
                <li>
                  <a href="explore-product.html">Product</a>
                </li>
                <li>
                  <a href="creators.html">All NFTs</a>
                </li>
                <li>
                  <a href="">Social Network</a>
                </li>
                <li>
                  <a href="contact.html">Domain Names</a>
                </li>
                <li>
                  <a href="collection.html">Collectibles</a>
                </li>
              </ul>
            </div>

            <div className="footer-widget">
              <h4 className="widget-title">Explore</h4>
              <ul>
                <li>
                  <a href="explore-product.html">Featured Drops</a>
                </li>
                <li>
                  <a href="explore-three.html">Live Auctions</a>
                </li>
                <li>
                  <a href="creators.html">All NFTs</a>
                </li>
                <li>
                  <a href="collection.html">Collections</a>
                </li>
                <li>
                  <a href="creators.html">Top Seller</a>
                </li>
                <li>
                  <a href="product-details.html">Items Details</a>
                </li>
              </ul>
            </div>

            <div className="footer-widget">
              <h4 className="widget-title">Supports</h4>
              <ul>
                <li>
                  <a href="dashboard-settings.html">Settings & Privacy</a>
                </li>
                <li>
                  <a href="support-faq.html">Help & Support</a>
                </li>
                <li>
                  <a href="explore-four.html">Live Auctions</a>
                </li>
                <li>
                  <a href="product-details.html">Item Details</a>
                </li>
                <li>
                  <a href="support-faq.html">24/7 Supports</a>
                </li>
                <li>
                  <a href="blog.html">Blog</a>
                </li>
              </ul>
            </div>

            <div className="footer-widget ">
              <h4 className="widget-title">Recent Sold Out</h4>
              <a href="blog-details.html" className="recent-sold-out">
                <div className="widget-img-thumb">
                  <img
                    src={
                      require(`../../../assets/img/recent-sold-author-1.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="recent-sold-out-info">
                  <span className="recent-sold-out-author-name">
                    Benjamin HR
                  </span>
                  <span className="recent-sold-out-place-bit">
                    20+ Place Bit
                  </span>
                  <span className="recent-sold-out-place-bit-price">
                    0.568ETH
                  </span>
                </div>
              </a>
              <a href="product-details.html" className="recent-sold-out">
                <div className="widget-img-thumb">
                  <img
                    src={
                      require(`../../../assets/img/recent-sold-author-2.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="recent-sold-out-info">
                  <span className="recent-sold-out-author-name">
                    Alexander Ak
                  </span>
                  <span className="recent-sold-out-place-bit">
                    14+ Place Bit
                  </span>
                  <span className="recent-sold-out-place-bit-price">
                    0.568ETH
                  </span>
                </div>
              </a>
              <a href="explore-product.html" className="recent-sold-out">
                <div className="widget-img-thumb">
                  <img
                    src={
                      require(`../../../assets/img/recent-sold-author-3.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="recent-sold-out-info">
                  <span className="recent-sold-out-author-name">
                    William Jeck
                  </span>
                  <span className="recent-sold-out-place-bit">
                    12+ Place Bit
                  </span>
                  <span className="recent-sold-out-place-bit-price">
                    0.568ETH
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <div className="footer-bottom-left text-start">
                2022 © All rights reserved by
                <a href="#" className="company-name m-2">
                  QuomodoTheme
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="footer-bottom-right text-end">
                <a href="privacy-policy.html">Privacy Policy</a>
                <a href="support-faq.html">Terms & Conditions</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHomeOne;
