import React, { useEffect } from "react";
import BackToTop from "../../lib/BackToTop";

function BacktoTopCom({ className }) {
  useEffect(() => {
    BackToTop(".scrollTopBtn");
  });
  return (
    <button
      type="button"
      id="scrollTopBtn"
      className={`scrollTopBtn ${className || ""}`}
      title="Go to top"
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
}

export default BacktoTopCom;
