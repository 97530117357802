import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function Creator() {
  return (
    <>
      <Layout>
        <Breadcrumb sectionTitle="Creators" sectionName="Creators" />
        <MainSection />
      </Layout>
    </>
  );
}

export default Creator;
