import React from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import ExploreProduct from "../../HomeOne/ExploreProduct";

function FilterpPage() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Explore Products"
          sectionName=" Explore Products"
        />
        <ExploreProduct datas={livebindingData} />
      </Layout>
    </>
  );
}

export default FilterpPage;
