import React from "react";

function ClientSection({ className }) {
  return (
    <section className={`our-client-area off-black-bg ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div
            className="section-title-box text-center mb-50 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <p className="sub-title">Our Clients</p>
            <h3 className="section-title">
              Backed by top firms & industry leaders
            </h3>
          </div>
        </div>
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-1.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-2.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-3.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-4.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-5.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-6.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-7.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-8.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-9.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-10.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-11.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="client-box single-top-seller">
              <div className="single-top-seller-border">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="client-img text-center">
                  <img
                    src={require(`../../assets/img/client-12.png`).default}
                    alt=""
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientSection;
