import React from "react";
import useToggle from "../../hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne";
import BacktoTopCom from "./BacktoTopCom";

function Layout({ children }) {
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderHomeOne action={setDrawer.toggle} />
      {children && children}
      <FooterHomeOne />
      <BacktoTopCom />
    </>
  );
}

export default Layout;
