import React from "react";

function Hero() {
  return (
    <section className="nft-hero-area d-flex align-items-center home-version-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-6 d-flex align-items-center">
            <div
              className="hero-left wow animated fadeInLeft"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <h2 className="hero-title">
                Discover Digital Art <br />
                Sell Your Specific <span className="nft-text-stroke">NFT</span>
              </h2>
              <p>
                Partner with one of the world’s largest retailers to showcase
                your brand and products.
              </p>
              <a
                href="#"
                className="nft-hero-btn nft-highlight-bg-color boxed-btn"
              >
                Explore More
              </a>
              <a href="#" className="nft-hero-btn style-2 boxed-btn">
                Create Now
              </a>
            </div>
          </div>
          <div
            className="col-xl-6 col-md-6 wow animated fadeInRight"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div className="hero-img-style-2 text-end">
              <img
                src={require(`../../assets/img/hero-home-2.png`).default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="animate-shape-1">
        <img
          src={require(`../../assets/img/animate-shape-1.png`).default}
          alt=""
        />
      </div>
      <div className="animate-shape-2">
        <img
          src={require(`../../assets/img/animate-shape-3.png`).default}
          alt=""
        />
      </div>
      <div className="animate-shape-3">
        <img
          src={require(`../../assets/img/animate-shape-2.png`).default}
          alt=""
        />
      </div>
      <div className="animate-shape-5">
        <img
          src={require(`../../assets/img/animate-shape-2.png`).default}
          alt=""
        />
      </div>
      <div className="animate-shape-4">
        <img
          src={require(`../../assets/img/animate-shape-4.png`).default}
          alt=""
        />
      </div>
      <div className="animate-shape-5">
        <img
          src={require(`../../assets/img/animate-shape-4.png`).default}
          alt=""
        />
      </div>
    </section>
  );
}

export default Hero;
