import React from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import NewsItem from "../../HomeThree/NewsItem";

function LiveExplorer() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Live Explore Carousel"
          sectionName="LiveExploreCarousel"
        />
        <NewsItem datas={livebindingData} />
      </Layout>
    </>
  );
}

export default LiveExplorer;
