import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function UploadVarient() {
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Upload Variants"
          sectionName="Upload Variants"
        />
        <MainSection />
      </Layout>
    </>
  );
}

export default UploadVarient;
