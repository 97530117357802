import React from "react";

function Navigations() {
  return (
    <div className="nft-main-menu">
      <nav id="mobile-menu">
        <ul id="menu-navbar">
          <li>
            <a href="/">Home</a>
            <ul className="drop-down-menu">
              <li>
                <a href="/">Home 1</a>
              </li>
              <li>
                <a href="/home-two">Home 2</a>
              </li>
              <li>
                <a href="/home-three">Home 3</a>
              </li>
              <li>
                <a href="/home-four">Home 4</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="#">Explore</a>
            <ul className="drop-down-menu">
              <li>
                <a href="/explore-product">Explore Filter</a>
              </li>
              <li>
                <a href="/explore-one">Explore Isotop</a>
              </li>
              <li>
                <a href="/explore-two">Explore Carousel</a>
              </li>
              <li>
                <a href="/explore-three">Live Explore</a>
              </li>
              <li>
                <a href="/explore-four">Live Explore Carousel</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">Pages</a>
            <div className="nft-megamenu">
              <div className="nft-megamenu-box">
                <div className="single-megamenu">
                  <ul className="mega-menu-item">
                    <h6 className="mega-menu-item-title">Home Pages</h6>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/home-two">Home two</a>
                    </li>
                    <li>
                      <a href="/home-three">Home three</a>
                    </li>
                    <li>
                      <a href="/home-four">Home four</a>
                    </li>
                  </ul>
                </div>
                <div className="single-megamenu">
                  <ul className="mega-menu-item">
                    <h6 className="mega-menu-item-title">Pages</h6>
                    <li>
                      <a href="/author">Author</a>
                    </li>
                    <li>
                      <a href="/collection">Our Collections</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                    <li>
                      <a href="/dashboard-settings">Dashboard Settings</a>
                    </li>
                  </ul>
                </div>
                <div className="single-megamenu">
                  <ul className="mega-menu-item">
                    <h6 className="mega-menu-item-title">Pages</h6>
                    <li>
                      <a href="/create-nft">Create NFTs</a>
                    </li>
                    <li>
                      <a href="/creators">Creators</a>
                    </li>
                    <li>
                      <a href="/support-faq">Support & FAQ</a>
                    </li>
                    <li>
                      <a href="/product-details">Product Details</a>
                    </li>
                  </ul>
                </div>
                <div className="single-megamenu">
                  <ul className="mega-menu-item">
                    <h6 className="mega-menu-item-title">Pages</h6>
                    <li>
                      <a href="/login">Login</a>
                    </li>
                    <li>
                      <a href="/registration">Registration</a>
                    </li>
                    <li>
                      <a href="/upload-variants">Upload Variants</a>
                    </li>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a href="#">Others</a>
            <ul className="drop-down-menu">
              <li>
                <a href="/creators">Creators</a>
              </li>
              <li>
                <a href="/create-nft">Create NFT</a>
              </li>
              <li>
                <a href="#">Menu Level Drop</a>
                <ul className="drop-down-menu-level-2">
                  <li>
                    <a href="/collection">Collections</a>
                  </li>
                  <li>
                    <a href="/product-details">Product Details</a>
                  </li>
                  <li>
                    <a href="#">Menu Level Drop</a>
                    <ul className="drop-down-menu-level-3">
                      <li>
                        <a href="#">Menu Level Three</a>
                      </li>
                      <li>
                        <a href="#">Menu Level Three</a>
                      </li>
                      <li>
                        <a href="#">Menu Level Three</a>
                      </li>
                      <li>
                        <a href="#">Menu Level Three</a>
                      </li>
                      <li>
                        <a href="#">Menu Level Three</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="login.html">Login</a>
                  </li>
                  <li>
                    <a href="/registration">Registration</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/upload-variants">Upload Variants</a>
              </li>
              <li>
                <a href="#">Menu Level One</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="blog.html">Blog</a>
            <ul className="drop-down-menu">
              <li>
                <a href="/blog-single-col">Blog Single Column</a>
              </li>
              <li>
                <a href="/blog-two-col">Blog Two Column</a>
              </li>
              <li>
                <a href="/blog-three-col">Blog Three Column</a>
              </li>
              <li>
                <a href="/blog-four-col">Blog Four Column</a>
              </li>
              <li>
                <a href="/blog-details">Blog Details</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navigations;
