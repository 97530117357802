import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function SingleBlogColum() {
  return (
    <>
      <Layout>
        <Breadcrumb sectionTitle="Blog Colum" sectionName="Blog" />
        <MainSection />
      </Layout>
    </>
  );
}

export default SingleBlogColum;
