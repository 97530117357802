import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function PrivacyPolicy() {
  return (
    <Layout>
      <Breadcrumb sectionTitle="Privacy policy" sectionName="Privacy policy" />
      <MainSection />
    </Layout>
  );
}

export default PrivacyPolicy;
