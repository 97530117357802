import React from "react";

function MainSection() {
  return (
    <section className="our-collection-page black-bg">
      <div className="container">
        <div className="row mb-60">
          <div
            className="row wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-1.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-1.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-1.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-1.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">
                      Abstract Art
                    </span>
                    <span className="product-collection-stock">1024 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-2.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-2.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-2.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-2.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Photography</span>
                    <span className="product-collection-stock">854 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-3.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-3.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-3.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-3.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Diamond Dog</span>
                    <span className="product-collection-stock">788 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-4.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-4.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-4.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-4.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">3D Desing</span>
                    <span className="product-collection-stock">340 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-5.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-5.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-5.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-5.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Traveling</span>
                    <span className="product-collection-stock">680 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-6.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-6.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-6.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-6.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">
                      Abstract Background
                    </span>
                    <span className="product-collection-stock">793 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-7.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-7.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-7.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-7.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">
                      Against Dark Cave
                    </span>
                    <span className="product-collection-stock">654 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-8.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-8.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-8.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-8.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">
                      Diamond Animals
                    </span>
                    <span className="product-collection-stock">510 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-9.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-9.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-9.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-9.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">
                      Pop Art Style
                    </span>
                    <span className="product-collection-stock">987 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-10.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-10.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-10.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-10.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Photography</span>
                    <span className="product-collection-stock">854 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-11.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-11.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-11.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-11.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Diamond Dog</span>
                    <span className="product-collection-stock">788 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="product-collection-wrapper">
                <div className="product-collection-box">
                  <div className="item1">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-12.1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item2">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-12.2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item3">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-12.3.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item4">
                    <a href="product-details.html">
                      <img
                        src={
                          require(`../../../assets/img/product-collection-box-12.4.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-collection-footer d-flex align-items-center justify-content-between">
                  <div className="product-collection-info">
                    <span className="product-collection-name">Fast Light</span>
                    <span className="product-collection-stock">600 Items</span>
                  </div>
                  <a href="product-details.html" className="boxed-btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row  wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
        >
          <ul className="product-pagination">
            <li className="active">
              <a href="#">01</a>
            </li>
            <li>
              <a href="#">02</a>
            </li>
            <li>
              <a href="#">03</a>
            </li>
            <li>
              <a href="#">04</a>
            </li>
            <li>
              <a href="#">....</a>
            </li>
            <li>
              <a href="#">09</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
