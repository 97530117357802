import React from "react";
import { livebinding } from "../../data/LiveBinding.json";
import Layout from "../Helpers/Layout";
import CreateAndSell from "./CreateAndSell";
import ExploreProduct from "./ExploreProduct";
import Hero from "./Hero";
import LiveBinding from "./LiveBinding";
import NewestSection from "./NewestSection";
import TopCollection from "./TopCollection";
import TopSaller from "./TopSaller";

function HomeOne() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Hero />
        <LiveBinding datas={livebindingData} />
        <CreateAndSell />
        <NewestSection datas={livebindingData} />
        <TopSaller />
        <ExploreProduct datas={livebindingData} />
        <TopCollection />
      </Layout>
    </>
  );
}

export default HomeOne;
