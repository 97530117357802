import React from "react";

function MainSection() {
  return (
    <section className="creators-area black-bg">
      <div className="container">
        <div className="row">
          <div
            className="creators-title d-flex justify-content-between align-items-center mb-50  wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <h1 className="section-title">Our Best Creators</h1>
            <span className="boxed-filter-btn">
              Short By :
              <button type="button" className="boxed-btn">
                Top Rated
                <span className="btn-icon">
                  <i className="fas fa-angle-down"></i>
                </span>
              </button>
            </span>
          </div>
        </div>
        <div
          className="row mb-60 wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">01</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-01.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Brodband</span>
                  <span className="seller-author-sell-amount">$2500,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">02</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-02.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Alexander</span>
                  <span className="seller-author-sell-amount">$3000,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">03</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-03.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">William Jeck</span>
                  <span className="seller-author-sell-amount">$5500,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">04</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-04.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Henry Jhon</span>
                  <span className="seller-author-sell-amount">$2800,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">05</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-05.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">James Thomas</span>
                  <span className="seller-author-sell-amount">$1400,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">06</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-06.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Anthony Roy</span>
                  <span className="seller-author-sell-amount">$2800,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">07</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-07.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Chritopher</span>
                  <span className="seller-author-sell-amount">$2700,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">08</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-08.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Elijabeth Ran</span>
                  <span className="seller-author-sell-amount">$3200,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">09</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-01.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Brodband HR</span>
                  <span className="seller-author-sell-amount">$1600,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">10</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-02.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Michel Raw</span>
                  <span className="seller-author-sell-amount">$1800,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">11</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-03.png`).default}
                    alt=""
                  />
                  <span className="seller-author-badge">
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Liam Dylan</span>
                  <span className="seller-author-sell-amount">$1900,000</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-6">
            <a href="#" className="single-top-seller">
              <div className="single-top-seller-border d-flex align-items-center">
                <div className="seller-rank-wrapper">
                  <span className="seller-rank-number">12</span>
                </div>
                <div className="seller-author-img">
                  <img
                    src={require(`../../../assets/img/seller-04.png`).default}
                    alt=""
                  />
                </div>
                <div className="seller-author-info">
                  <span className="seller-autor-name">Thomas Jar</span>
                  <span className="seller-author-sell-amount">$2200,000</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="row">
          <ul className="product-pagination">
            <li className="active">
              <a href="#">01</a>
            </li>
            <li>
              <a href="#">02</a>
            </li>
            <li>
              <a href="#">03</a>
            </li>
            <li>
              <a href="#">04</a>
            </li>
            <li>
              <a href="#">....</a>
            </li>
            <li>
              <a href="#">09</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
