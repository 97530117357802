import React from "react";

function BlogSlideBar() {
  return (
    <div className="blog-sidebar">
      <aside
        className="widget widget-search wow animated fadeInUp"
        data-wow-duration="2000ms"
        data-wow-delay="200ms"
      >
        <form className="search-form" action="#" method="post">
          <input type="search" name="s" placeholder="Search..." />
          <button type="submit">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </aside>
      <aside className="widget widget-categories">
        <h3 className="sidebar-widget-title">Categories</h3>
        <ul>
          <li className="active">
            <a href="#">Counsalting</a>
            <span>(2)</span>
          </li>
          <li>
            <a href="#">Marketing</a>
            <span>(6)</span>
          </li>
          <li>
            <a href="#">Strategy</a>
            <span>(6)</span>
          </li>
          <li>
            <a href="#">Analysis</a>
            <span>(7)</span>
          </li>
          <li>
            <a href="#">Business</a>
            <span>(9)</span>
          </li>
          <li>
            <a href="#">Finance</a>
            <span>(3)</span>
          </li>
          <li>
            <a href="#">News</a>
            <span>(5)</span>
          </li>
        </ul>
      </aside>
      <aside
        className="widget widget-trend-post wow animated fadeInUp"
        data-wow-duration="2000ms"
        data-wow-delay="400ms"
      >
        <h3 className="sidebar-widget-title">Popular Posts</h3>

        <a
          href="blog-details.html"
          className="popular-post d-flex justify-content-start align-items-center"
        >
          <img
            src={require(`../../assets/img/popular-blog-post-1.png`).default}
            alt=""
          />
          <div className="popular-post-info">
            <p>
              More important feature for designer a long established fact that
            </p>
            <span>
              <i className="far fa-clock"></i> 17 Feb 2022
            </span>
          </div>
        </a>
        <a
          href="blog-details.html"
          className="popular-post d-flex justify-content-start align-items-center"
        >
          <img
            src={require(`../../assets/img/popular-blog-post-2.png`).default}
            alt=""
          />
          <div className="popular-post-info">
            <p>
              NFTs are too important for best artist as opposed to using
              'Content here
            </p>
            <span>
              <i className="far fa-clock"></i> 17 Feb 2022
            </span>
          </div>
        </a>
        <a
          href="blog-details.html"
          className="popular-post d-flex justify-content-start align-items-center"
        >
          <img
            src={require(`../../assets/img/popular-blog-post-3.png`).default}
            alt=""
          />
          <div className="popular-post-info">
            <p>
              More important feature for designer a long established fact that
            </p>
            <span>
              <i className="far fa-clock"></i> 17 Feb 2022
            </span>
          </div>
        </a>
        <a
          href="blog-details.html"
          className="popular-post d-flex justify-content-start align-items-center"
        >
          <img
            src={require(`../../assets/img/popular-blog-post-4.png`).default}
            alt=""
          />
          <div className="popular-post-info">
            <p>
              Provide a rare theme in this market a great image for great
              artists
            </p>
            <span>
              <i className="far fa-clock"></i> 17 Feb 2022
            </span>
          </div>
        </a>
      </aside>
      <aside
        className="widget wow animated fadeInUp"
        data-wow-duration="2000ms"
        data-wow-delay="500ms"
      >
        <h3 className="sidebar-widget-title">Popular Tags</h3>
        <div className="tags">
          <a className="active" href="#">
            Bitcoin
          </a>
          <a href="#">NFT</a>
          <a href="#">Bids</a>
          <a href="#">Marketplace</a>
          <a href="#">Art's</a>
          <a href="#">Token</a>
          <a href="#">Crypto</a>
          <a href="#">Wallet</a>
        </div>
      </aside>
      <aside
        className="widget wow animated fadeInUp"
        data-wow-duration="2000ms"
        data-wow-delay="600ms"
      >
        <div className="widget-subscribe">
          <h3 className="subscribe-form-title">
            Subscribe to our newsletter to receive exclusive offers.
          </h3>
          <form className="subscribe-form" action="#" method="post">
            <input
              type="email"
              name="email"
              placeholder="Enter email"
              required
            />
            <button className="nft-highlight-bg-color" type="submit">
              Join us
            </button>
          </form>
        </div>
      </aside>
    </div>
  );
}

export default BlogSlideBar;
