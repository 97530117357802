import React from "react";

function MainSection() {
  return (
    <section className="upload-variants-area black-bg">
      <div className="container">
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
        >
          <div className="col-xl-12 text-center mb-70">
            <h1 className="section-title">Upload Variants</h1>
          </div>
          <div className="upload-variants-wrapper d-flex justify-content-center align-items-center">
            <div className="upload-variants-box text-center">
              <span className="upload-variants-img">
                <img
                  src={
                    require(`../../../assets/img/upload-variants-img-1.jpg`)
                      .default
                  }
                  alt=""
                />
              </span>
              <a href="#" className="boxed-btn">
                Create Single
              </a>
            </div>
            <div className="upload-variants-box text-center">
              <span className="upload-variants-img">
                <img
                  src={
                    require(`../../../assets/img/upload-variants-img-2.jpg`)
                      .default
                  }
                  alt=""
                />
              </span>
              <a href="#" className="boxed-btn">
                Create Multiple
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
