import React from "react";
import CounterUp from "../Helpers/CounterUp";

function TeamArea() {
  return (
    <section className="our-teadm-area black-bg">
      <div className="container">
        <div className="row">
          <div
            className="section-title-box text-center mb-60 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <p className="sub-title">Our Team</p>
            <h3 className="section-title">Expart Team Member</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div
              className="single-member-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="single-member-img">
                <img
                  src={require(`../../assets/img/team-member-1.jpg`).default}
                  alt=""
                />
              </div>
              <div className="single-member-info">
                <h4 className="member-name">Jessica Brown</h4>
                <span className="member-desig">Co-founder of Scalar</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-member-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="single-member-img">
                <img
                  src={require(`../../assets/img/team-member-2.jpg`).default}
                  alt=""
                />
              </div>
              <div className="single-member-info">
                <h4 className="member-name">Jessica Brown</h4>
                <span className="member-desig">Co-founder of Scalar</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-member-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="single-member-img">
                <img
                  src={require(`../../assets/img/team-member-3.jpg`).default}
                  alt=""
                />
              </div>
              <div className="single-member-info">
                <h4 className="member-name">Jessica Brown</h4>
                <span className="member-desig">Co-founder of Scalar</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-member-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="single-member-img">
                <img
                  src={require(`../../assets/img/team-member-4.jpg`).default}
                  alt=""
                />
              </div>
              <div className="single-member-info">
                <h4 className="member-name">Jessica Brown</h4>
                <span className="member-desig">Co-founder of Scalar</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            id="company-details"
            className="company-details d-flex justify-content-between align-items-center mt-140"
          >
            <div
              className="single-company-info-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="100ms"
            >
              <span className="company-info-icon">
                <img
                  src={
                    require(`../../assets/img/company-details-icon-1.png`)
                      .default
                  }
                  alt=""
                />
              </span>
              <p className="company-details-number">
                <span className="c-d-n">
                  <CounterUp endValue={112} sectionSelect="company-details" />
                </span>
              </p>
              <span className="company-details-name">Employees</span>
            </div>
            <div
              className="single-company-info-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <span className="company-info-icon">
                <img
                  src={
                    require(`../../assets/img/company-details-icon-2.png`)
                      .default
                  }
                  alt=""
                />
              </span>
              <p className="company-details-number">
                <span className="c-d-n">
                  <CounterUp endValue={1.5} sectionSelect="company-details" />
                </span>
                M
              </p>
              <span className="company-details-name">Collections</span>
            </div>
            <div
              className="single-company-info-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="300ms"
            >
              <span className="company-info-icon">
                <img
                  src={
                    require(`../../assets/img/company-details-icon-3.png`)
                      .default
                  }
                  alt=""
                />
              </span>
              <p className="company-details-number">
                <span className="c-d-n">
                  <CounterUp endValue={400} sectionSelect="company-details" />
                </span>
                K+
              </p>
              <span className="company-details-name">Users</span>
            </div>
            <div
              className="single-company-info-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <span className="company-info-icon">
                <img
                  src={
                    require(`../../assets/img/company-details-icon-4.png`)
                      .default
                  }
                  alt=""
                />
              </span>
              <p className="company-details-number">
                <span className="c-d-n">
                  <CounterUp endValue={24} sectionSelect="company-details" />
                </span>
                M+
              </p>
              <span className="company-details-name">NFTs</span>
            </div>
            <div
              className="single-company-info-box text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="500ms"
            >
              <span className="company-info-icon">
                <img
                  src={
                    require(`../../assets/img/company-details-icon-5.png`)
                      .default
                  }
                  alt=""
                />
              </span>
              <p className="company-details-number">
                $
                <span className="c-d-n">
                  <CounterUp endValue={2} sectionSelect="company-details" />
                </span>
                B+
              </p>
              <span className="company-details-name">Volume</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamArea;
