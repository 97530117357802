import React from "react";

function MainSection() {
  return (
    <section className="black-bg">
      <div className="container">
        <div
          className="nft-form-wrapper wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <div className="nft-form-inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="nft-form-box">
                  <div className="nft-form-title-box mb-50">
                    <h4 className="nft-form-title">Create Your Account</h4>
                    <p>
                      Already have an account? <a href="login.html">Log In</a>
                    </p>
                  </div>

                  <form action="#" method="post">
                    <div className="row">
                      <div className="col-xl-12">
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="First name"
                          required
                        />
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="text"
                          id="lname"
                          name="lname"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email address"
                          required
                        />
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Create Password"
                          required
                        />
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="password"
                          id="re-password"
                          name="re-password"
                          placeholder="Re Password"
                          required
                        />
                      </div>
                      <div className="col-xl-12">
                        <label className="nft-form-checklist login-remember">
                          Allow to all terms & condition
                          <input type="checkbox" required />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <input
                          type="submit"
                          value="Sign Up"
                          className="nft-form-button"
                        />
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <input
                          type="submit"
                          value="Login"
                          className="nft-form-button nft-form-button-2"
                        />
                      </div>
                    </div>
                  </form>

                  <div className="nft-form-title-box mt-60 mb-25">
                    <h4 className="nft-form-title">Another Way to Login</h4>
                    <p>Most popular gaming digital nft market place</p>
                  </div>
                  <div className="ntf-login-with-box">
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <a href="#" className="ntf-login-with">
                          <span className="login-with-btn-icon">
                            <i className="fab fa-google"></i>
                          </span>
                          Log in with Google
                        </a>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <a href="#" className="ntf-login-with">
                          <span className="login-with-btn-icon">
                            <i className="fab fa-twitter"></i>
                          </span>
                          Log in with Twitter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-lg-6">
                <div className="nft-form-img text-end">
                  <img
                    src={
                      require(`../../../assets/img/nft-form-img-2.jpg`).default
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
