import React, { useState } from "react";

function MainSection() {
  const [img, setImage] = useState(null);
  const changeImg = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <section className="create-nft black-bg">
      <div className="container">
        <div className="row wow animated fadeInUp">
          <div className="col-xl-8 col-md-8">
            <div className="create-nft-form-wrapper">
              <div className="create-nft-form-border">
                <form action="#" method="post">
                  <div className="row">
                    <div className="col-xl-12">
                      <label htmlFor="product-name">Product Name</label>
                      <br />
                      <input
                        type="text"
                        id="product-name"
                        name="product-name"
                        placeholder="Write product name"
                        required
                      />
                    </div>
                    <div className="col-xl-12">
                      <label htmlFor="product-desc">Description:</label>
                      <br />
                      <textarea
                        id="product-desc"
                        name="product-desc"
                        rows="4"
                        cols="50"
                        placeholder="Write a short discription"
                      ></textarea>
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="p-price">Price</label>
                      <br />
                      <input
                        type="number"
                        id="p-price"
                        name="p-price"
                        placeholder="Type product price"
                        required
                      />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="p-category">Category</label>
                      <br />
                      <select name="p-category" id="p-category">
                        <option className="ddd">Select Category</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                    <div className="col-xl-4">
                      <label htmlFor="p-royality">Royality</label>
                      <br />
                      <input type="number" id="p-royality" name="p-royality" />
                    </div>
                    <div className="col-xl-4">
                      <label htmlFor="p-size">Size</label>
                      <br />
                      <input type="number" id="p-size" name="p-size" value="" />
                    </div>
                    <div className="col-xl-4">
                      <label htmlFor="p-property">Property</label>
                      <br />
                      <input
                        type="text"
                        id="p-property"
                        name="p-property"
                        value=""
                      />
                    </div>

                    <div className="col-xl-12">
                      <div className="nft-form-checklist-wrapper">
                        <label className="nft-form-checklist">
                          Put on sell
                          <input type="checkbox" checked="checked" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="nft-form-checklist">
                          Instant sale price
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="nft-form-checklist">
                          Unlock Purchased
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="nft-form-checklist">
                          I agree to all terms & conditions
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-12 mt-40">
                      <input
                        className="boxed-btn"
                        type="submit"
                        value="Submit Item"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-4">
            <h4 className="create-nft-title">Create New NFT</h4>

            <div className="upload-text">
              <p>Upload File</p>
              <span>Drag or choose your file to upload</span>
            </div>

            <label
              htmlFor="file-upload"
              className="custom-file-upload"
              style={{ padding: img ? "0" : "92px 0" }}
            >
              {img ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <img
                    src={img}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <div style={{ width: "100%", height: "100%" }}>
                  <div className="upload-icon">
                    <img
                      src={
                        require(`../../../assets/img/upload-icon.png`).default
                      }
                      alt=""
                    />
                  </div>
                  <p>Choose a File</p>
                  <span>
                    PNG, GIF, WEBP, MP4 or MP3
                    <br />
                    Max 1 GB
                  </span>
                </div>
              )}
            </label>
            <input
              className="nft-file-upload"
              id="file-upload"
              type="file"
              onChange={changeImg}
            />

            <div className="create-nft-footer-info">
              <h6>Note:</h6>
              <p>Service fee 2.5%</p>
              <p>
                You will receive:
                <span className="nft-highlight-color">22.00 ETH $45,000</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
