import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function CollectionPage() {
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Our Collections"
          sectionName="Our collections"
        />
        <MainSection />
      </Layout>
    </>
  );
}

export default CollectionPage;
