import React from "react";

function Breadcrumb({ className, sectionTitle, sectionName }) {
  return (
    <div
      className={`breadcrumb-area breadcrumb-bg overflow-hidden ${
        className || ""
      }`}
    >
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-md-6 col-sm-6 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div className="breadcrumb-title">
              <h3 className="page-title">{sectionTitle}</h3>
              <p>
                <a className="home" href="/">
                  Home
                </a>
                <span className="page-title-seperator">/</span>
                <span className="current-page">{sectionName}</span>
              </p>
            </div>
          </div>
          <div
            className="col-xl-6 col-md-6 col-sm-6 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div className="breadcrumb-img text-end">
              <img
                className="rotate-img"
                src={require(`../../assets/img/breadcrumb-right.png`).default}
                alt=""
              />
              <img
                className="breadcrumb-abs-img"
                src={require(`../../assets/img/breadcrumb-middle.png`).default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
