import React, { useRef } from "react";
import RowCardStyleOne from "../Cards/RowCardStyleOne";
import SliderCom from "../Helpers/SliderCom";

function NewsItem({ datas }) {
  const sliderSelector = useRef(null);
  const settings = {
    infinite: true,
    margin: 50,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const prevHandler = () => sliderSelector.current.slickPrev();
  const nextHandler = () => sliderSelector.current.slickNext();
  return (
    <section className="newest-item-slider-area off-black-bg home-2">
      <div className="container">
        <div className="row">
          <div
            className="d-flex justify-content-between align-items-center mb-50 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <h1 className="section-title">Newest Items</h1>
          </div>
        </div>
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
        >
          <div className="newest-item-slider-wrapper position-relative">
            <span onClick={prevHandler} className="prev slick-arrow">
              <i className="fa fa-chevron-left"></i>
            </span>
            <SliderCom selector={sliderSelector} settings={settings}>
              {datas &&
                datas.length > 0 &&
                datas
                  .slice(-4)
                  .map((item) => (
                    <RowCardStyleOne key={Math.random()} datas={item} />
                  ))}
            </SliderCom>
            <span onClick={nextHandler} className="next slick-arrow">
              <i className="fa fa-chevron-right"></i>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsItem;
