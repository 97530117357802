import React from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import Layout from "../../Helpers/Layout";
import ExploreProduct from "./ExploreProduct";
import Hero from "./Hero";

function Author() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Hero />
        <ExploreProduct datas={livebindingData} className="pb-0" />
      </Layout>
    </>
  );
}

export default Author;
