import React from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import ExploreProduct from "../../HomeThree/ExploreProduct";

function IsotopPage() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Explore Isotop"
          sectionName="Explore Isotop"
        />
        <ExploreProduct datas={livebindingData} />
      </Layout>
    </>
  );
}

export default IsotopPage;
