import React from "react";
import AccordionSupport from "../DashBoardUser/AccordionSupport";

function MainSection() {
  return (
    <section className="black-bg">
      <div className="container">
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
        >
          <div className="col-xl-6">
            <div className="support-and-faq-box lowlight-bg-color nft-border lh-24">
              <div className="others-post-box">
                <h3 className="single-post-title">How to start as a seller?</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorems my text ever since the 1500s, when
                  an unknown printer took
                </p>
                <p>
                  A galley of type and scrambled it to make a type specimen
                  book. Ithas survived not electronic typesetting, remaining
                  essentially unchanged.
                </p>
              </div>
              <div className="others-post-box">
                <h3 className="single-post-title">
                  What happens when there are no orders?
                </h3>
                <p>
                  Variations of passages of Lorem Ipsum available, but the
                  majority have suffered al or randomiseds words which don't
                  look even slightly believable. If you are going to be sure
                  there isn't anything embarrassing hidden in the middle of
                  text.
                </p>
                <p>
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks a tor on the Internet. It uses a dictionary
                  of over 200 Latin words, combined with as rate Lorem Ipsum
                  which looks reasonable.
                </p>
              </div>
              <div className="others-post-box">
                <h3 className="single-post-title">
                  Why are you getting rejected shares?
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorems my text ever since the 1500s, when
                  an unknown printer took{" "}
                </p>
                <p>
                  A galley of type and scrambled it to make a type specimen
                  book. Ithas survived not electronic typesetting, remaining
                  essentially unchanged.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <AccordionSupport />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
