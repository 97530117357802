import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      setItem(value);
    }
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  <a href="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times"></i>
                  </a>
                </div>
                <div
                  className="offcanvas-brand text-center"
                  style={{ marginBottom: "40px" }}
                >
                  <img
                    className="mb-20"
                    src={require(`../../assets/img/logoRgb.png`).default}
                    alt=""
                  />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li
                      onClick={(e) => handler(e, "home")}
                      id="home"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">Home</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "home" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/">Home 1</Link>
                        </li>
                        <li>
                          <Link to="/home-two">Home 2</Link>
                        </li>
                        <li>
                          <Link to="/home-three">Home 3</Link>
                        </li>
                        <li>
                          <Link to="/home-four">Home 4</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "about")}
                      id="about"
                      className="menu-item-has-children active"
                    >
                      <a href="/about">About</a>
                    </li>
                    <li
                      onClick={(e) => handler(e, "explore")}
                      id="explore"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">explore</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "explore" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/explore-product">Explore filter </Link>
                        </li>
                        <li>
                          <Link to="/explore-one">Explore isotop</Link>
                        </li>
                        <li>
                          <Link to="/explore-two">Explore carousel</Link>
                        </li>
                        <li>
                          <Link to="/explore-three">Live explore</Link>
                        </li>
                        <li>
                          <Link to="/explore-four">Live explore carousel</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "blog")}
                      id="blog"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">blog</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "blog" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/blog-single-col">blog single colum</Link>
                        </li>
                        <li>
                          <Link to="/blog-two-col">blog two colum</Link>
                        </li>
                        <li>
                          <Link to="/blog-three-col">blog three colum</Link>
                        </li>
                        <li>
                          <Link to="/blog-four-col">blog four colum</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "contact")}
                      id="contact"
                      className="menu-item-has-children active"
                    >
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="$">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fas fa-envelope"></i> support@appie.com
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-phone"></i> +(642) 342 762 44
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-map-marker-alt"></i> 442 Belle
                        Terre St Floor 7, San Francisco, AV 4206
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
