import React, { useState } from "react";

function DetailsContent() {
  const details = [
    {
      id: 1,
      title: "Details",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor modi ut, debitis delectus deserunt ipsa ullam necessitatibus expedita laboriosam mollitia veniam rerum error istes, tempora vitae. As totam exercitationem odit.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    },
    {
      id: 2,
      title: "Activity",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor modi ut, debitis delectus deserunt ipsa ullam necessitatibus expedita laboriosam mollitia veniam rerum error istes, tempora vitae. As totam exercitationem odit.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    },
    {
      id: 3,
      title: "Info",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor modi ut, debitis delectus deserunt ipsa ullam necessitatibus expedita laboriosam mollitia veniam rerum error istes, tempora vitae. As totam exercitationem odit.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    },
  ];
  const [detailTab, setValue] = useState(details[0].id);
  const handler = (value) => setValue(value);
  return (
    <>
      <div className="product-tab product-d-tab">
        <div className="tab">
          {details &&
            details.length > 0 &&
            details.map((item) => (
              <button
                key={Math.round()}
                className={`tablinks ${detailTab === item.id ? "active" : ""}`}
                type="button"
                onClick={() => handler(item.id)}
              >
                {item.title}
              </button>
            ))}
        </div>
      </div>
      <div className="row mt-40">
        <div className="product-description">
          {details &&
            details.length > 0 &&
            details.map((item) => (
              <div
                key={Math.round()}
                id="p-details"
                className="tabcontent"
                style={{ display: item.id === detailTab ? "block" : "" }}
              >
                <p>{item.description}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default DetailsContent;
