import React from "react";

function TopSeller() {
  return (
    <section className="top-seller-area black-bg home-2">
      <div className="container">
        <div className="row">
          <div
            className="d-flex justify-content-between align-items-center mb-50 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <h1 className="section-title">
              Top Seller in <span className="highlight-color-1">1</span> Day{" "}
              <span className="section-title-after-icon">
                <i className="fas fa-angle-down"></i>
              </span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-8 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div className="row">
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">01</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-01.png`).default}
                        alt=""
                      />
                      <span className="seller-author-badge">
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Brodband</span>
                      <span className="seller-author-sell-amount">
                        $2500,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">02</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-02.png`).default}
                        alt=""
                      />
                      <span className="seller-author-badge">
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Alexander</span>
                      <span className="seller-author-sell-amount">
                        $3000,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">03</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-03.png`).default}
                        alt=""
                      />
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">William Jeck</span>
                      <span className="seller-author-sell-amount">
                        $5500,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">04</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-04.png`).default}
                        alt=""
                      />
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Elijabeth Ran</span>
                      <span className="seller-author-sell-amount">
                        $3200,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">05</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-05.png`).default}
                        alt=""
                      />
                      <span className="seller-author-badge">
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Benjamin HR</span>
                      <span className="seller-author-sell-amount">
                        $1600,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">06</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-06.png`).default}
                        alt=""
                      />
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">James Thomas</span>
                      <span className="seller-author-sell-amount">
                        $1400,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">07</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-07.png`).default}
                        alt=""
                      />
                      <span className="seller-author-badge">
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Cristopher</span>
                      <span className="seller-author-sell-amount">
                        $2700,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <a href="#" className="single-top-seller text-center">
                  <div className="single-top-seller-border">
                    <div className="seller-rank-wrapper">
                      <span className="seller-rank-number">08</span>
                    </div>
                    <div className="seller-author-img">
                      <img
                        src={require(`../../assets/img/seller-08.png`).default}
                        alt=""
                      />
                      <span className="seller-author-badge">
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="seller-author-info">
                      <span className="seller-autor-name">Henry Jhon</span>
                      <span className="seller-author-sell-amount">
                        $2800,000
                      </span>
                    </div>
                    <div className="seller-author-follow-btn">
                      <button type="button" className="boxed-btn">
                        Follow
                      </button>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-xl-4 col-md-12 text-md-center text-sm-center wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <a href="#" className="nft-advertisement-box box-1">
              <img
                src={require(`../../assets/img/ad-post-1.jpg`).default}
                alt=""
              />
            </a>
            <a href="#" className="nft-advertisement-box">
              <img
                src={require(`../../assets/img/ad-post-2.jpg`).default}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopSeller;
