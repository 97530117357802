import React from "react";
import ColumCardStyleOne from "../Cards/ColumCardStyleOne";
import SelectBox from "../Helpers/SelectBox";

function ExploreProduct({ datas, className }) {
  const likes = ["Like filter one", "Like filter two", "Like filter three"];
  const category = [
    "Category Name One",
    "Category Name two",
    "Category Name three",
  ];
  const collections = ["Collection One", "Collection two", "Collection three"];
  const sellType = ["Sell type One", "Sell type two", "Sell type three"];
  const priceRange = ["Low", "Medium", "High"];
  return (
    <section className={`explore-product-area off-black-bg ${className || ""}`}>
      <div className="container">
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
        >
          <div className="d-flex justify-content-between align-items-center mb-50">
            <h1 className="section-title">Explore Product</h1>
            <button type="button" className="boxed-btn">
              Filter
            </button>
          </div>
        </div>
        <div className="row">
          <SelectBox datas={likes} title="Likes" />
          <SelectBox datas={category} title="Category" />
          <SelectBox datas={collections} title="Collections" />
          <SelectBox datas={sellType} title="Sale Type" />
          <SelectBox datas={priceRange} title="Price Range" />
        </div>
        <div className="mb-50"></div>
        <div className="row">
          {datas &&
            datas.length > 0 &&
            datas.slice(0, 8).map((item) => (
              <div key={Math.random()} className="col-xl-3 col-md-6">
                <ColumCardStyleOne datas={item} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ExploreProduct;
