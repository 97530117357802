import React from "react";

function MainSection() {
  return (
    <section className="our-blog-area black-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">The beginner’s guide to creating</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-2.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">12 reasons to sell your NFTs</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-3.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">Purpose classes & motivation</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">Canada is a great for NFT's</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-5.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">Search your rare art form best artist</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-6.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">Provide a rare theme in this market</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-7.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">The beginner’s guide to creating</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="single-post">
                  <div className="single-post-img">
                    <img
                      src={
                        require(`../../../assets/img/single-post-img-8.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="single-post-details">
                    <div className="single-post-meta d-flex justify-content-between align-items-center">
                      <a
                        href="#"
                        className="single-post-author d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={
                            require(`../../../assets/img/single-post-author-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <p>
                          <span className="post-by">Post By</span>
                          <span className="post-author-name">Thomas Jar</span>
                        </p>
                      </a>
                      <a href="#" className="single-post-date">
                        <i className="far fa-clock"></i>
                        17 Feb 2022
                      </a>
                    </div>
                    <div className="single-post-desc">
                      <h3 className="single-post-title">
                        <a href="#">10 tips for avoiding scams & staying</a>
                      </h3>
                      <p>
                        It is a long established fact that a readers will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-60">
          <ul
            className="product-pagination wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <li className="active">
              <a href="#">01</a>
            </li>
            <li>
              <a href="#">02</a>
            </li>
            <li>
              <a href="#">03</a>
            </li>
            <li>
              <a href="#">04</a>
            </li>
            <li>
              <a href="#">....</a>
            </li>
            <li>
              <a href="#">09</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
