import React from "react";

function TopCollection({ className }) {
  return (
    <section className={`top-collection-area black-bg ${className || ""}`}>
      <div className="container">
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="100ms"
        >
          <div className="d-flex justify-content-between align-items-center mb-50">
            <h1 className="section-title">Top Collection</h1>
            <a href="collection.html" className="boxed-btn">
              View All
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div
              className="product-collection-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="product-collection-box">
                <div className="item1">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-1.1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item2">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-1.2.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item3">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-1.3.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item4">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-1.4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="product-collection-footer d-flex align-items-center justify-content-between">
                <div className="product-collection-info">
                  <span className="product-collection-name">Abstract Art</span>
                  <span className="product-collection-stock">1024 Items</span>
                </div>
                <a href="explore-product.html" className="boxed-btn">
                  View All
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="product-collection-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="product-collection-box">
                <div className="item1">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-2.1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item2">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-2.2.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item3">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-2.3.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item4">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-2.4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="product-collection-footer d-flex align-items-center justify-content-between">
                <div className="product-collection-info">
                  <span className="product-collection-name">Photography</span>
                  <span className="product-collection-stock">854 Items</span>
                </div>
                <a href="explore-product.html" className="boxed-btn">
                  View All
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="product-collection-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="product-collection-box">
                <div className="item1">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-3.1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item2">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-3.2.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item3">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-3.3.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item4">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-3.4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="product-collection-footer d-flex align-items-center justify-content-between">
                <div className="product-collection-info">
                  <span className="product-collection-name">Diamond Dog</span>
                  <span className="product-collection-stock">788 Items</span>
                </div>
                <a href="explore-product.html" className="boxed-btn">
                  View All
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="product-collection-wrapper wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="product-collection-box">
                <div className="item1">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-4.1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item2">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-4.2.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item3">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-4.3.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="item4">
                  <a href="/product-details">
                    <img
                      src={
                        require(`../../assets/img/product-collection-box-4.4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="product-collection-footer d-flex align-items-center justify-content-between">
                <div className="product-collection-info">
                  <span className="product-collection-name">3D Desing</span>
                  <span className="product-collection-stock">340 Items</span>
                </div>
                <a href="explore-product.htmll" className="boxed-btn">
                  View All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopCollection;
