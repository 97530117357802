import React, { useRef } from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import ColumCardStyleOne from "../../Cards/ColumCardStyleOne";
import SliderCom from "../../Helpers/SliderCom";

function RelatedProduct() {
  const liveData = livebinding;
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const reladedProductSlider = useRef(null);
  const prevHandler = () => reladedProductSlider.current.slickPrev();
  const nextHandler = () => reladedProductSlider.current.slickNext();
  return (
    <div className="related-products-slider position-relative">
      <span onClick={prevHandler} className="prev slick-arrow">
        <i className="fa fa-chevron-left"></i>
      </span>
      <SliderCom selector={reladedProductSlider} settings={settings}>
        {liveData &&
          liveData.length > 0 &&
          liveData
            .slice(0, 6)
            .map((item) => (
              <ColumCardStyleOne key={Math.random()} datas={item} />
            ))}
      </SliderCom>
      <span onClick={nextHandler} className="next slick-arrow">
        <i className="fa fa-chevron-right"></i>
      </span>
    </div>
  );
}

export default RelatedProduct;
