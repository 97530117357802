import React from "react";
import { livebinding } from "../../data/LiveBinding.json";
import Layout from "../Helpers/Layout";
import CreateAndSell from "../HomeOne/CreateAndSell";
import ExploreProduct from "../HomeOne/ExploreProduct";
import LiveBinding from "../HomeOne/LiveBinding";
import NewestSection from "../HomeOne/NewestSection";
import TopCollection from "../HomeOne/TopCollection";
import TopSaller from "../HomeOne/TopSaller";
import Hero from "./Hero";

function HomeTwo() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Hero />
        <LiveBinding className="home-version-one" datas={livebindingData} />
        <CreateAndSell sectionStyle="home-version-one" />
        <NewestSection className="home-version-one" datas={livebindingData} />
        <TopSaller className="home-version-one" />
        <ExploreProduct className="home-version-one" datas={livebindingData} />
        <TopCollection className="home-version-one" />
      </Layout>
    </>
  );
}

export default HomeTwo;
