import React, { useRef } from "react";
import ColumCardStyleOne from "../../Cards/ColumCardStyleOne";
import SliderCom from "../../Helpers/SliderCom";

function ProductCarousel({ datas }) {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const selectorSlider = useRef(null);
  const handlerPrev = () => selectorSlider.current.slickPrev();
  const handlerNext = () => selectorSlider.current.slickNext();
  return (
    <section className="product-carousel-area black-bg ">
      <div className="container">
        <div className="row">
          <div className="related-products-slider position-relative">
            <span onClick={handlerPrev} className="prev slick-arrow">
              <i className="fa fa-chevron-left"></i>
            </span>
            <SliderCom selector={selectorSlider} settings={settings}>
              {datas &&
                datas.length > 0 &&
                datas
                  .slice(0, 5)
                  .map((item) => <ColumCardStyleOne datas={item} />)}
            </SliderCom>
            <span onClick={handlerNext} className="next slick-arrow">
              <i className="fa fa-chevron-right"></i>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductCarousel;
