import React from "react";

function AreaMap({ mapUrl }) {
  return (
    <div className="row">
      <div className="col-12">
        <iframe
          title="contact mail"
          src={mapUrl}
          style={{ width: "100%", height: "500px" }}
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default AreaMap;
