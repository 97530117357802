import React from "react";
import useToggle from "../../hooks/useToggle";

function RowCardStyleOne({ datas }) {
  const [toggle, setToggle] = useToggle(false);
  const [review, setReview] = useToggle(false);
  return (
    <div className="newest-item-slide d-flex justify-content-between">
      <div className="newest-item-img">
        <img
          src={require(`../../assets/img/${datas.thumbnail}`).default}
          alt=""
        />
      </div>
      <div className="newest-item-content text-center">
        <h4 className="newest-item-heading">Diamond Color Animals #14</h4>
        <div className="newest-item-author-box d-flex justify-content-between align-items-center">
          <div className="newest-item-author-info d-flex justify-content-between align-items-center">
            <div className="newest-item-author-img">
              <img
                src={require(`../../assets/img/${datas.profile_photo}`).default}
                alt=""
              />
            </div>
            <div className="newest-item-author-link">
              <a className="newest-item-author" href="#">
                <span className="newest-item-author-name">{datas.name}</span>
                <span className="newest-item-author-short-name">
                  {datas.username}
                </span>
              </a>
            </div>
          </div>
          <div className="newest-item-author-events">
            <span
              className={`love-react home-2 ${review ? "active" : ""}`}
              id="love-react-100"
              onClick={() => setReview.set(true)}
            >
              <i className="fas fa-heart"></i>
            </span>
            <span onClick={setToggle.toggle} className="item-click-menu">
              <i className="fas fa-ellipsis-h"></i>
            </span>
            <ul
              id="item-click-menu-wrapper-100"
              className={`item-click-menu-wrapper ${toggle ? "active" : ""}`}
            >
              <li>
                <a href="#">
                  <i className="fas fa-share-alt"></i>
                  <span>Share</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="far fa-flag"></i>
                  <span>Report</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fas fa-bookmark"></i>
                  <span>Bookmark</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="bidding-ending-box">
          <span className="bidding-ending-title">Bid Ending Soon</span>
          <div className="bidding-ending-counter-box">
            <ul>
              <li>
                <span className="days bidding-ending-counter-number">01</span>
                <span className="bidding-ending-counter-text">Days</span>
              </li>
              <li>
                <span className="hours bidding-ending-counter-number">01</span>
                <span className="bidding-ending-counter-text">Hours</span>
              </li>
              <li>
                <span className="minutes bidding-ending-counter-number">
                  01
                </span>
                <span className="bidding-ending-counter-text">Min</span>
              </li>
              <li>
                <span className="seconds bidding-ending-counter-number">
                  01
                </span>
                <span className="bidding-ending-counter-text">Sec</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="product-details-footer d-flex justify-content-between">
          <span className="product-price">{datas.price}</span>
          <a href="#" className="boxed-btn">
            Place a Bit
          </a>
        </div>
      </div>
    </div>
  );
}

export default RowCardStyleOne;
