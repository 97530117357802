import React, { useState } from "react";
import DetailsContent from "./DetailsContent";
import RelatedProduct from "./RelatedProduct";

function MainSection() {
  const imageTabContents = [
    {
      id: 1,
      small: "product-details-sm-1.jpg",
      big: "product-details-big-1.jpg",
    },
    {
      id: 2,
      small: "product-details-sm-2.jpg",
      big: "product-details-big-2.jpg",
    },
    {
      id: 3,
      small: "product-details-sm-3.jpg",
      big: "product-details-big-3.jpg",
    },
  ];
  const [imageTab, setImgTab] = useState(imageTabContents[0].id);
  const imgTabHandler = (value) => setImgTab(value);
  return (
    <>
      <section className="black-bg">
        <div className="container">
          <div className="row wow animated fadeInUp">
            <div className="col-xl-6 d-flex align-items-center">
              <div className="product-img-tab">
                {imageTabContents &&
                  imageTabContents.length > 0 &&
                  imageTabContents.map((imgTabItem) => (
                    <span
                      className={`tab-links ${
                        imageTab === imgTabItem.id ? "active" : ""
                      }`}
                      onClick={() => imgTabHandler(imgTabItem.id)}
                    >
                      <img
                        src={
                          require(`../../../assets/img/${imgTabItem.small}`)
                            .default
                        }
                        alt=""
                      />
                    </span>
                  ))}
              </div>
              <div className="product-big-img">
                {imageTabContents &&
                  imageTabContents.length > 0 &&
                  imageTabContents.map((imgTabItem) => (
                    <div
                      id="product-details-sm-1"
                      className="tab-content"
                      style={{
                        display: imgTabItem.id === imageTab ? "block" : "none",
                      }}
                    >
                      <img
                        src={
                          require(`../../../assets/img/${imgTabItem.big}`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-xl-6">
              <div className="product-details-content">
                <h4 className="product-name">Diamond Color Animals #14</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alterati on in some
                  form, by injected humour, or randomised words which don't
                  randomisesd words which don't look even slightly believable.
                  If you are going to use a passage
                </p>
                <div className="product-author-box d-flex justify-content-between align-items-center">
                  <a
                    href="#"
                    className="product-author-link d-flex align-items-center"
                  >
                    <div className="product-author-img">
                      <img
                        src={
                          require(`../../../assets/img/product-author.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="product-author-info">
                      <span className="product-author-name">Rankat MH</span>
                      <span>By @rankat</span>
                    </div>
                  </a>
                  <div className="product-click-button">
                    <span
                      onClick="singleItemClick('explore-product-click-menu-wrapper-1')"
                      className="item-click-menu"
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </span>
                    <span
                      onClick="singleItemLoveClick('explore-product-love-react-1')"
                      className="love-react"
                      id="explore-product-love-react-1"
                    >
                      <i className="fas fa-heart"></i>
                    </span>
                  </div>
                  <ul
                    id="explore-product-click-menu-wrapper-1"
                    className="item-click-menu-wrapper"
                  >
                    <li>
                      <a href="#" tabIndex="0">
                        <i className="fas fa-share-alt"></i>
                        <span>Share</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" tabIndex="0">
                        <i className="far fa-flag"></i>
                        <span>Report</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" tabIndex="0">
                        <i className="fas fa-bookmark"></i>
                        <span>Bookmark</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="product-bid-ending-box">
                  <p>Bid Ending Soon</p>
                  <ul className="product-counter-timer">
                    <li>
                      <span className="days count-number">15</span>
                      <span className="count-text">Days</span>
                    </li>
                    <li>
                      <span className="hours count-number">18</span>
                      <span className="count-text">Hours</span>
                    </li>
                    <li>
                      <span className="min count-number">50</span>
                      <span className="count-text">Min</span>
                    </li>
                    <li>
                      <span className="sec count-number">30</span>
                      <span className="count-text">Sec</span>
                    </li>
                  </ul>
                </div>
                <div className="product-details-footer">
                  <span className="product-price">12.568ETH</span>
                  <a href="#" className="boxed-btn" tabIndex="0">
                    Place a Bit
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-100">
            <DetailsContent />
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-6 mb-50 mt-100">
              <h1 className="section-title">Related Projects</h1>
            </div>
          </div>

          <div className="row">
            <RelatedProduct />
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
