import React from "react";
import BlogSlideBar from "../BlogSlideBar";

function MainSection() {
  return (
    <section className="our-blog-area black-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-8">
            <div className="single-blog-details">
              <div
                className="single-blog-img wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <img
                  src={
                    require(`../../../assets/img/single-blog-details-img.jpg`)
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="single-post-meta d-flex justify-content-between align-items-center">
                <a
                  href="#"
                  className="single-post-author d-flex justify-content-start align-items-center"
                >
                  <img
                    src={
                      require(`../../../assets/img/single-post-author-1.png`)
                        .default
                    }
                    alt=""
                  />
                  <p>
                    <span className="post-by">Post By</span>
                    <span className="post-author-name">Thomas Jar</span>
                  </p>
                </a>
                <div className="single-post-meta-info">
                  <a href="#" className="single-post-date">
                    <i className="far fa-comments"></i>
                    25
                  </a>
                  <a href="#" className="single-post-date">
                    <i className="far fa-clock"></i>
                    17 Feb 2022
                  </a>
                </div>
              </div>
              <h3 className="blog-details-title">
                The beginner’s guide to creating & selling digital art NFTs
              </h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure theres isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet.
              </p>

              <p>
                It uses a dictionary of over 200 Latin words, combined with a
                handful of model sentence structures, to generate Lorem Ipsum
                which looks reasonable. The generated Lorem Ipsum is therefore
                always free from repetition, injected humour, or
                non-characteristic words etc
              </p>

              <div className="blog-details-quote">
                <p className="quote-p">
                  Roll Out New Features Without Hurting Loyal Users Unicode
                  Character Sets The Ultim An Overview UX Design as Deliverables
                  all the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks
                </p>
                <p className="nft-highlight-color ">John B. Thomas</p>
              </div>

              <div className="blog-details-img-box d-flex align-items-center justify-content-between">
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/img/blog-details-img-1.jpg`)
                        .default
                    }
                    alt=""
                  />
                </a>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/img/blog-details-img-2.jpg`)
                        .default
                    }
                    alt=""
                  />
                </a>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/img/blog-details-img-3.jpg`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <h3 className="blog-details-title">
                The beginner’s guide to creating & selling digital art NFTs
              </h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure theres isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet.
              </p>

              <p>
                It uses a dictionary of over 200 Latin words, combined with a
                handful of model sentence structures, to generate Lorem Ipsum
                which looks reasonable. The generated Lorem Ipsum is therefore
                always free from repetition, injected humour, or
                non-characteristic words etc
              </p>

              <ul className="blog-details-text-list">
                <li>Should more of us wear face masks?</li>
                <li>Why some countries wear face masks and others don’t</li>
                <li>Coronavirus: Are homemade face masks safe?</li>
              </ul>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure theres isn't anything embarrassing
                hidden in the middle of text.
              </p>
              <div className="blog-details-footer-meta d-flex justify-content-between align-items-center">
                <div className="post-tags d-flex">
                  <h3>Tags:</h3>
                  <a href="#">NTF</a>
                  <a href="#">Bitcoin</a>
                  <a href="#">Marketplace</a>
                  <a href="#">Wallet</a>
                </div>
                <div className="post-share-on d-flex">
                  <h3>Share:</h3>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>

              <div className="comment-area">
                <h3 className="blog-details-title">3 Comments</h3>
                <ol className="comment-list">
                  <li>
                    <div className="single-comment">
                      <img
                        src={
                          require(`../../../assets/img/comment-author-1.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5>
                        <a href="#">Subash Chandra</a>
                      </h5>
                      <a className="comment-reply-link" href="#">
                        <i className="fas fa-reply"></i>
                      </a>
                      <div className="comment">
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by inject humour, or randomised words
                          which don't look even slightly believable
                        </p>
                      </div>
                    </div>
                    <ul className="children">
                      <li>
                        <div className="single-comment">
                          <img
                            src={
                              require(`../../../assets/img/comment-author-2.png`)
                                .default
                            }
                            alt=""
                          />
                          <h5>
                            <a href="#">Thomas Jar</a>
                          </h5>
                          <a className="comment-reply-link" href="#">
                            <i className="fas fa-reply"></i>
                          </a>
                          <div className="comment">
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by humour, or randomised
                              words which don't look even slightly believable
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="single-comment">
                      <img
                        src={
                          require(`../../../assets/img/comment-author-3.png`)
                            .default
                        }
                        alt=""
                      />
                      <h5>
                        <a href="#">Elijabeth Rayan</a>
                      </h5>
                      <a className="comment-reply-link" href="#">
                        <i className="fas fa-reply"></i>
                      </a>
                      <div className="comment">
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by inject humour, or randomised words
                          which don't look even slightly believable
                        </p>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
              <div className="comment-form-area">
                <h3 className="blog-details-title">Leave an option</h3>
                <div className="comment-form nft-main-form ">
                  <form action="#" method="post">
                    <div className="row">
                      <div className="col-xl-6">
                        <input
                          type="text"
                          id="text"
                          name="text"
                          placeholder="Full Name"
                          required
                        />
                      </div>
                      <div className="col-xl-6">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="col-xl-12">
                        <textarea
                          name="comment-opnion"
                          placeholder="Tell us about your opinion"
                        ></textarea>
                      </div>
                      <div className="col-xl-6">
                        <button className="boxed-btn" type="submit">
                          POST OPINION
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <BlogSlideBar />
          </div>
        </div>
        <div className="row mt-60">
          <ul
            className="product-pagination wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <li className="active">
              <a href="#">01</a>
            </li>
            <li>
              <a href="#">02</a>
            </li>
            <li>
              <a href="#">03</a>
            </li>
            <li>
              <a href="#">04</a>
            </li>
            <li>
              <a href="#">....</a>
            </li>
            <li>
              <a href="#">09</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
