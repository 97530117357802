import React from "react";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import MainSection from "./MainSection";

function Login() {
  return (
    <>
      <Layout>
        <Breadcrumb sectionTitle="Login" sectionName="Login" />
        <MainSection />
      </Layout>
    </>
  );
}

export default Login;
