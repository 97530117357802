import React from "react";
import Layout from "../Helpers/Layout";
import Breadcrumb from "./Breadcrumb";
import ClientSection from "./ClientSection";
import TeamArea from "./TeamArea";
import Testimonial from "./Testimonial";

function About() {
  return (
    <>
      <Layout>
        <Breadcrumb sectionTitle="About page" sectionName="about" />
        <ClientSection />
        <TeamArea />
        <Testimonial />
      </Layout>
    </>
  );
}

export default About;
