import React from "react";

function MainSection() {
  return (
    <section className="black-bg">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-12 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div className="privacy-policy-box lowlight-bg-color nft-border lh-24">
              <div className="others-post-box">
                <h3 className="single-post-title">
                  01. Welcome to Beatplan Privacy Policy
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dum my text ever since the 1500s, when an unknown
                  printer took
                </p>
                <p>
                  A galley of type and scrambled it to make a type specimen
                  book. Ithas survived not only five centuries, but also the
                  leap into as electronic typesetting, remaining essentially
                  unchanged. It was popul arised in the 1960s with the
                </p>
                <p>
                  release of Letraset sheets containing Lorem Ipsum passages,
                  and more recently with desktop publishing software like Aldus
                  A pageMaker including versions of Lorem Ipsum
                </p>
              </div>
              <div className="others-post-box">
                <h3 className="single-post-title">
                  02. The type of personal information we collect
                </h3>
                <p>
                  It might sound ridiculous but the explosive market of
                  crypto-collectibles and crypto-art is no joke. I investigate
                  cryptocurrenciei and have academic publications on Bitcoin
                  markets. To help you understand what an NFT is and why they’re
                  becoming so popi ular, here’s an explainer to make sense of it
                  all.
                </p>

                <p>
                  The bidding on Musk’s tweet has already topped $1 million and
                  millions more are pouring into the market — he has since tweet
                  ed, “Actually, doesn’t feel quite right selling this. Will
                  pass.” And sites like NBA Top Shot (where you can buy, sell
                  and trade digiti al NBA cards) have individual cards selling
                  for over US$200,000.
                </p>

                <p>
                  A non-fungible token (NFT) is a digital file with verified
                  identity and ownership. This verification is done using
                  blockchain techni ology. Blockchain technology, simply put, is
                  an un-hackable system based on the mathematics of
                  cryptographys. So, that’s why you hear a lot of “crypto” when
                  referring to NFTs — crypto-art, crypto-collectibles, etc.
                </p>
              </div>
              <div className="others-post-box">
                <h3 className="single-post-title">
                  03. How we collect personal information
                </h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by ins ected humour, or randomised words which don't
                  look even slightly believable. If you are going to use
                </p>
                <p>
                  A passage of Lorem Ipsum, you need to be sure there isn't
                  anything embarrassing hidden in the middle of text. All the
                  Lorem as Ipsum generators on the Internet tend to repeat
                  predefined
                </p>
              </div>
              <div className="others-post-box">
                <h3 className="single-post-title">
                  04. Collect personal information
                </h3>
                <p>
                  Variations of passages of Lorem Ipsum available, but the
                  majority have suffered alteration in some form, by injected
                  humour, a or randomised words which don't look even slightly
                  believable. If you are going to use a passage of Loremsi Ipsum
                  you need to be sure there isn't anything embarrassing hidden
                  in the middle of text.
                </p>

                <p>
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks as necessary, making this the first true
                  genera tor on the Internet. It uses a dictionary of over 200
                  Latin words, combined with a handful of model sentence
                  structures, to gene rate Lorem Ipsum which looks reasonable.
                  The generated Lorem Ipsum is therefore always free
                </p>
                <p className="mt-40">
                  <i>Last updated Feb 16, 2022</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
