import React, { useState } from "react";

function AccordionSupport() {
  const accordion = [
    {
      id: 1,
      title:
        "If I sell NFTs by accepting only cryptocurrencies, do I have to pay  VAT?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
    {
      id: 2,
      title: "Can NFTs be used as an investment?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
    {
      id: 3,
      title: "Does Sleepminting on NFT pose a security threat?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
    {
      id: 4,
      title:
        " Isn’t it a problem that minting NFT is so expensive for small digital artists?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
    {
      id: 5,
      title: "How secure is an ERC721 NFT?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
    {
      id: 6,
      title: " How can we ensure the persistence of NFTs?",
      description:
        "There are many variations of passages of Lorem Ipsum available but the majority have suffer d alteration in some form, by injected humour or randomised words which don't look even slig tly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure",
    },
  ];
  const [acc, setAcc] = useState(accordion[0].id);
  const accHandler = (value) => {
    setAcc(value);
  };

  return (
    <div className="accordion nft-faq-accordion" id="accordionExample">
      {accordion &&
        accordion.length > 0 &&
        accordion.map((acItem) => (
          <div key={Math.random()} className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                onClick={() => accHandler(acItem.id)}
                className={`accordion-button ${
                  acItem.id !== acc ? "collapsed" : ""
                }`}
                type="button"
              >
                {acItem.title}
              </button>
            </h2>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse ${
                acItem.id === acc ? "show" : ""
              }`}
            >
              <div className="accordion-body">{acItem.description}</div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default AccordionSupport;
