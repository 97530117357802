import React from "react";
import useToggle from "../../hooks/useToggle";
import CountDown from "../Helpers/CountDown";

function ColumCardStyleOne({ datas }) {
  const [filter, setFilter] = useToggle(false);
  const [review, setReview] = useToggle(false);
  return (
    <div
      className="single-bidding-box wow animated fadeInUp"
      data-wow-duration="2000ms"
      data-wow-delay="200ms"
    >
      <div
        className="bidding-box-bg d-flex justify-content-between"
        style={{
          backgroundImage: `url(${
            require(`../../assets/img/${datas.thumbnail}`).default
          })`,
        }}
      >
        <span className="item-click-menu" onClick={setFilter.toggle}>
          <i className="fas fa-ellipsis-h"></i>
        </span>
        <span
          className={`love-react ${review ? "active" : ""}`}
          onClick={() => setReview.set(true)}
          id="love-react-1"
        >
          <i className="fas fa-heart"></i>
        </span>
        <a href="#" className="boxed-btn">
          Place Bit
        </a>
        <ul
          id="item-click-menu-wrapper-1"
          className={`item-click-menu-wrapper ${filter ? "active" : ""}`}
        >
          <li>
            <a href="#">
              <i className="fas fa-share-alt"></i>
              <span>Share</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="far fa-flag"></i>
              <span>Report</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-bookmark"></i>
              <span>Bookmark</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="bidding-duration bidding-duration-box-1">
        <CountDown lastDate={datas.last_date} />
      </div>
      <div className="bidding-product-name">
        <a href="/product-details" className="text-capitalize">
          {datas.title}
        </a>
      </div>
      <div className="bidding-info d-flex justify-content-between align-items-center">
        <div className="bidding-item-info d-flex justify-content-between align-items-center">
          <img
            src={require(`../../assets/img/${datas.profile_photo}`).default}
            alt=""
          />
          <a className="bidding-user text-capitalize" href="#">
            {datas.name}
            <span className="text-lowercase">{datas.username}</span>
          </a>
        </div>
        <div className="bidding-item-price text-end">
          <span className="price-number"> {datas.price} </span>
          <span className="price-text"> Current Bit </span>
        </div>
      </div>
      <div className="bidding-item-footer-info d-flex justify-content-between align-items-center">
        <span>12+ Place Bit.</span>
        <a href="#" className="boxed-btn">
          New
        </a>
      </div>
    </div>
  );
}

export default ColumCardStyleOne;
