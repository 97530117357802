import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import BlogDetails from "./components/Blog/BlogDetails";
import FourBlogColum from "./components/Blog/FourBlogColum";
import SingleBlogColum from "./components/Blog/SingleBlogColum";
import ThreeBlogColum from "./components/Blog/ThreeBlogColum";
import TwoBlogColum from "./components/Blog/TwoBlogColum";
import CarouselPage from "./components/ExplorerPages/CarouselPage";
import FilterpPage from "./components/ExplorerPages/FilterPage";
import IsotopPage from "./components/ExplorerPages/IsotopPage";
import LiveExplorer from "./components/ExplorerPages/LiveExplorer";
import LiveExplorerCarousel from "./components/ExplorerPages/LiveExplorerCarousel/index";
import HomeFour from "./components/HomeFour/index";
import HomeOne from "./components/HomeOne";
import HomeThree from "./components/HomeThree/index";
import HomeTwo from "./components/HomeTwo";
import Author from "./components/Pages/Author";
import CollectionPage from "./components/Pages/CollectionPage";
import Contact from "./components/Pages/Contact";
import CreateNft from "./components/Pages/CreateNft";
import Creator from "./components/Pages/Creator";
import DashBoardUser from "./components/Pages/DashBoardUser";
import Login from "./components/Pages/Login";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy/index";
import ProductDetails from "./components/Pages/ProductDetails";
import Registration from "./components/Pages/Registration";
import SupportFaq from "./components/Pages/SupportFaq";
import UploadVarient from "./components/Pages/UploadVarient";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeOne />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/explore-product" element={<FilterpPage />} />
      <Route exact path="/explore-one" element={<IsotopPage />} />
      <Route exact path="/explore-two" element={<CarouselPage />} />
      <Route exact path="/explore-three" element={<LiveExplorer />} />
      <Route exact path="/explore-four" element={<LiveExplorerCarousel />} />
      <Route exact path="/author" element={<Author />} />
      <Route exact path="/collection" element={<CollectionPage />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/dashboard-settings" element={<DashBoardUser />} />
      <Route exact path="/create-nft" element={<CreateNft />} />
      <Route exact path="/creators" element={<Creator />} />
      <Route exact path="/support-faq" element={<SupportFaq />} />
      <Route exact path="/product-details" element={<ProductDetails />} />
      <Route exact path="/Login" element={<Login />} />
      <Route exact path="/registration" element={<Registration />} />
      <Route exact path="/upload-variants" element={<UploadVarient />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/blog-single-col" element={<SingleBlogColum />} />
      <Route exact path="/blog-two-col" element={<TwoBlogColum />} />
      <Route exact path="/blog-three-col" element={<ThreeBlogColum />} />
      <Route exact path="/blog-four-col" element={<FourBlogColum />} />
      <Route exact path="/blog-details" element={<BlogDetails />} />
    </Routes>
  );
}
