import React from "react";
import { livebinding } from "../../../data/LiveBinding.json";
import Breadcrumb from "../../About/Breadcrumb";
import Layout from "../../Helpers/Layout";
import ProductCarousel from "./ProductCarousel";

function CarouselPage() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Breadcrumb
          sectionTitle="Explore Carousel"
          sectionName="Explore Carousel"
        />
        <ProductCarousel datas={livebindingData} />
      </Layout>
    </>
  );
}

export default CarouselPage;
