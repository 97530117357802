import React from "react";
import ColumCardStyleOne from "../Cards/ColumCardStyleOne";

function NewestSection({ datas, className }) {
  return (
    <section className={`newest-items-area off-black-bg ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div
            className="d-flex justify-content-between align-items-center mb-50 wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="100ms"
          >
            <h1 className="section-title">Newest Items</h1>
            <a href="explore-product.html" className="boxed-btn">
              View All
            </a>
          </div>
        </div>
        <div className="row">
          {datas &&
            datas.length > 0 &&
            datas.slice(4, 8).map((item) => (
              <div key={Math.random()} className="col-xl-3 col-md-6">
                <ColumCardStyleOne datas={item} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default NewestSection;
