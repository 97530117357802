import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import AccordionSupport from "./AccordionSupport";

function MainSection() {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const getQuery = queryParams.get("query");
  const navigations = [
    {
      id: 1,
      name: "Profile",
      icon: "far fa-user",
    },
    {
      id: 2,
      name: "Links",
      icon: "fas fa-external-link-alt",
    },
    {
      id: 3,
      name: "Notification",
      icon: "far fa-bell",
    },
    {
      id: 4,
      name: "Others",
      icon: "fas fa-tag",
    },
    {
      id: 5,
      name: "Appearance",
      icon: "fas fa-braille",
    },
    {
      id: 6,
      name: "Support",
      icon: "fas fa-headphones",
    },
  ];
  const [tab, setTab] = useState(navigations[0].name);
  const tabHandler = (value) => {
    setTab(value);
  };
  const defaultCheck = (value) => {
    setTab(value);
  };
  window.addEventListener("load", () => {
    defaultCheck(getQuery);
  });
  return (
    <section className="black-bg">
      <div className="container">
        <div
          className="row wow animated fadeInUp"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <div className="col-xl-4 col-md-4">
            <div className="dashboard-menu lowlight-bg-color nft-border nft-box-padding">
              <p className="dashboard-menu-heading">
                <i className="fas fa-cog"></i>Settings
              </p>
              <ul className="dashboard-tab">
                {navigations &&
                  navigations.length > 0 &&
                  navigations.map((navItem) => (
                    <li
                      key={Math.random()}
                      className={`tab-links ${
                        navItem.name === tab ? "active" : ""
                      }`}
                      onClick={() => tabHandler(navItem.name)}
                    >
                      <i className={`${navItem.icon}`}></i>
                      {navItem.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-8 col-md-8">
            <div className="dashboard-tab-content lowlight-bg-color nft-border nft-box-padding">
              <div
                style={{ display: tab === "Profile" ? "block" : "" }}
                className="tab-content"
              >
                <div className="comment-form nft-main-form ">
                  <h4 className="dashboard-content-heading">
                    Profile Settings
                  </h4>
                  <form action="#" method="post">
                    <div className="row">
                      <div className="col-xl-4 col-md-5">
                        <div className="dash-left">
                          <label htmlFor="fname">First Name</label>
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            required=""
                          />

                          <label htmlFor="lname">Last Name</label>
                          <input
                            type="text"
                            id="lname"
                            name="lname"
                            required=""
                          />

                          <label htmlFor="username">Enter Username</label>
                          <input type="text" id="username" name="username" />

                          <label htmlFor="wallet-address">Wallet Address</label>
                          <input
                            type="text"
                            id="wallet-address"
                            name="username"
                          />

                          <button className="boxed-btn d-sm-none" type="submit">
                            Save Now
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-7">
                        <div className="dash-right ms-5">
                          <label
                            htmlFor="file-upload"
                            className="profile-file-upload mb-50"
                          >
                            <p>Profile Image</p>
                            <div className="upload-icon">
                              <img
                                src={
                                  require(`../../../assets/img/upload-icon-2.png`)
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <button type="button">Upload New Picture</button>
                            <button type="button">Delete</button>
                          </label>

                          <label
                            htmlFor="file-upload"
                            className="profile-file-upload"
                          >
                            <p>Banner Image</p>
                            <div className="upload-icon upload-icon-box">
                              <img
                                src={
                                  require(`../../../assets/img/upload-icon-2.png`)
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <button type="button">Upload New Picture</button>
                            <button type="button">Delete</button>
                          </label>
                          <button
                            className="boxed-btn d-none d-sm-block d-md-block"
                            type="submit"
                          >
                            Save Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                style={{ display: tab === "Links" ? "block" : "" }}
                className="tab-content"
              >
                <div className="comment-form nft-main-form ">
                  <h4 className="dashboard-content-heading">
                    Social Links Settings
                  </h4>
                  <form action="#" method="post">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="dash-left">
                          <label htmlFor="f">Facebook</label>
                          <input type="text" id="f" name="f" required="" />

                          <label htmlFor="t">Twitter</label>
                          <input type="text" id="t" name="t" required="" />

                          <label htmlFor="p">Pinterest</label>
                          <input type="text" id="p" name="p" />

                          <label htmlFor="l">Linkdin</label>
                          <input type="text" id="l" name="l" />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="dash-left">
                          <label htmlFor="f">Instagram</label>
                          <input type="text" id="f" name="f" required="" />

                          <label htmlFor="t">Google Plus</label>
                          <input type="text" id="t" name="t" required="" />

                          <label htmlFor="p">Dribble</label>
                          <input type="text" id="p" name="p" />

                          <label htmlFor="l">Behance</label>
                          <input type="text" id="l" name="l" />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <button className="boxed-btn" type="submit">
                          Save Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                style={{ display: tab === "Notification" ? "block" : "" }}
                className="tab-content"
              >
                <h4 className="dashboard-content-heading">Notifications</h4>
                <ul className="dash-notifications">
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-1.png`).default}
                        alt=""
                      />
                      You have an offer!
                    </a>
                    <span>New</span>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-2.png`).default}
                        alt=""
                      />
                      Congratulations! Your sale an item.
                    </a>
                    <span>New</span>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-3.png`).default}
                        alt=""
                      />
                      January freebies have arrived
                    </a>
                    <span>New</span>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-4.png`).default}
                        alt=""
                      />
                      A new rating has been received.
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-1.png`).default}
                        alt=""
                      />
                      You have an offer!
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-2.png`).default}
                        alt=""
                      />
                      Congratulations! Your sale an item.
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-3.png`).default}
                        alt=""
                      />
                      January freebies have arrived
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-4.png`).default}
                        alt=""
                      />
                      A new rating has been received.
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-1.png`).default}
                        alt=""
                      />
                      You have an offer!
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={require(`../../../assets/img/noti-2.png`).default}
                        alt=""
                      />
                      Congratulations! Your sale an item.
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{ display: tab === "Others" ? "block" : "" }}
                className="tab-content text-center"
              >
                <div className="dash-order">
                  <img
                    className="mb-90"
                    src={
                      require(`../../../assets/img/offer-content-img.png`)
                        .default
                    }
                    alt=""
                  />
                  <h4 className="">No collections to manage offers</h4>
                  <p>
                    You currently don't have any collections and items to manage
                    offers.
                  </p>
                </div>
              </div>
              <div
                style={{ display: tab === "Appearance" ? "block" : "" }}
                className="tab-content"
              >
                <h4 className="dashboard-content-heading">
                  Appearance Setting
                </h4>

                <div className="site-color-mode-box">
                  <p className="dashboard-menu-heading">Theme</p>
                  <button type="button" className="dark active">
                    <img
                      src={
                        require(`../../../assets/img/mode-light.png`).default
                      }
                      alt=""
                    />
                    Light Mode
                  </button>
                  <button type="button" className="light">
                    <img
                      src={
                        require(`../../../assets/img/mode-dark-white.png`)
                          .default
                      }
                      alt=""
                    />
                    Dark Mode
                  </button>
                </div>
              </div>
              <div
                style={{ display: tab === "Support" ? "block" : "" }}
                className="tab-content account-support"
              >
                <h4 className="dashboard-content-heading mb-25">
                  Account Support
                </h4>
                <AccordionSupport />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
