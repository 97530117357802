import React from "react";

function CreateAndSell({ sectionStyle }) {
  return (
    <section className={`create-and-sell-area black-bg ${sectionStyle || ""}`}>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mb-50">
            <h1
              className="section-title wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="100ms"
            >
              Create and sell your NFTs
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div
              className="single-create-sell-item text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="single-create-sell-item-border text-center">
                <div className="item-number d-flex align-items-center justify-content-center">
                  <span>01</span>
                </div>
                <div className="single-create-sell-item-icon">
                  {sectionStyle === "home-version-one" ? (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-1-gradient.png`)
                          .default
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-1.png`)
                          .default
                      }
                      alt=""
                    />
                  )}
                </div>
                <h3 className="single-create-sell-item-title">
                  Set up Your Wallet
                </h3>
                <p className="single-create-sell-item-desc">
                  There are many variations of passagi Ipsum available but the
                  majorty have eration in some form, by injected
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-create-sell-item text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="single-create-sell-item-border text-center">
                <div className="item-number d-flex align-items-center justify-content-center">
                  <span>02</span>
                </div>
                <div className="single-create-sell-item-icon">
                  {sectionStyle === "home-version-one" ? (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-2-gradient.png`)
                          .default
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-2.png`)
                          .default
                      }
                      alt=""
                    />
                  )}
                </div>
                <h3 className="single-create-sell-item-title">
                  Create Your Collection
                </h3>
                <p className="single-create-sell-item-desc">
                  There are many variations of passagi Ipsum available but the
                  majorty have eration in some form, by injected
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-create-sell-item text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="single-create-sell-item-border text-center">
                <div className="item-number d-flex align-items-center justify-content-center">
                  <span>03</span>
                </div>
                <div className="single-create-sell-item-icon">
                  {sectionStyle === "home-version-one" ? (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-3-gradient.png`)
                          .default
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-3.png`)
                          .default
                      }
                      alt=""
                    />
                  )}
                </div>
                <h3 className="single-create-sell-item-title">
                  Add Your NFT's
                </h3>
                <p className="single-create-sell-item-desc">
                  There are many variations of passagi Ipsum available but the
                  majorty have eration in some form, by injected
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div
              className="single-create-sell-item text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="single-create-sell-item-border text-center">
                <div className="item-number d-flex align-items-center justify-content-center">
                  <span>04</span>
                </div>
                <div className="single-create-sell-item-icon">
                  {sectionStyle === "home-version-one" ? (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-4-gradient.png`)
                          .default
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        require(`../../assets/img/single-create-sell-item-4.png`)
                          .default
                      }
                      alt=""
                    />
                  )}
                </div>
                <h3 className="single-create-sell-item-title">
                  Sell Your NFT's
                </h3>
                <p className="single-create-sell-item-desc">
                  There are many variations of passagi Ipsum available but the
                  majorty have eration in some form, by injected
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAndSell;
