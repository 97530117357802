import React from "react";
import { livebinding } from "../../data/LiveBinding.json";
import Layout from "../Helpers/Layout";
import CreateAndSell from "../HomeOne/CreateAndSell";
import ExploreProduct from "../HomeOne/ExploreProduct";
import LiveBinding from "../HomeOne/LiveBinding";
import NewestSection from "../HomeOne/NewestSection";
import TopCollection from "../HomeOne/TopCollection";
import TopSaller from "../HomeOne/TopSaller";
import Hero from "./Hero";

function HomeFour() {
  const livebindingData = livebinding;
  return (
    <>
      <Layout>
        <Hero />
        <LiveBinding datas={livebindingData} />
        <CreateAndSell />
        <NewestSection datas={livebindingData} />
        <TopSaller />
        <ExploreProduct datas={livebindingData} />
        <TopCollection />
      </Layout>
    </>
  );
}

export default HomeFour;
