import React from "react";

function MainSection() {
  return (
    <>
      <section className="black-bg">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center mb-50">
              <h1
                className="section-title wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                Quick Contact Address
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div
                className="single-create-sell-item text-center wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="single-create-sell-item-border text-center">
                  <div className="item-number d-flex align-items-center justify-content-center">
                    <span>01</span>
                  </div>
                  <div className="single-create-sell-item-icon">
                    <img
                      src={
                        require(`../../../assets/img/contact-icon-1.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h3 className="single-create-sell-item-title">
                    Contact Phone Number
                  </h3>
                  <p>
                    +444 555 666 777
                    <br />
                    +222 222 222 333
                    <br />
                    +44-208-1234567
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div
                className="single-create-sell-item text-center wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="single-create-sell-item-border text-center">
                  <div className="item-number d-flex align-items-center justify-content-center">
                    <span>02</span>
                  </div>
                  <div className="single-create-sell-item-icon">
                    <img
                      src={
                        require(`../../../assets/img/contact-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h3 className="single-create-sell-item-title">
                    Our Email Address
                  </h3>
                  <p>
                    admin@gmail.com <br />
                    example@gmail.com <br />
                    alparjahan@yahoo.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div
                className="single-create-sell-item text-center wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="single-create-sell-item-border text-center">
                  <div className="item-number d-flex align-items-center justify-content-center">
                    <span>03</span>
                  </div>
                  <div className="single-create-sell-item-icon">
                    <img
                      src={
                        require(`../../../assets/img/contact-icon-3.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <h3 className="single-create-sell-item-title">
                    Our Location
                  </h3>
                  <p>
                    Bawniya Rupan Tower <br />
                    5678 Bangla Main Road, cities 580 <br />
                    GBnagla, example 54786
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="nft-form-wrapper mt-140">
            <div className="nft-form-inner">
              <div className="row">
                <div className="col-xl-6">
                  <div className="nft-form-box">
                    <div className="nft-form-title-box mb-50">
                      <h4 className="nft-form-title">Drop Up A Message</h4>
                      <p>
                        Write to us or give us a call. We will reply to you as
                        soon as possible. But yes it can take up to 24 hours.
                      </p>
                    </div>

                    <form action="#" method="post">
                      <div className="row">
                        <div className="col-xl-12">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div className="col-xl-12">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Your Email Address"
                            required
                          />
                        </div>
                        <div className="col-xl-12">
                          <input
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                          />
                        </div>
                        <div className="col-xl-12">
                          <label className="nft-form-checklist login-remember">
                            Allow to all tearms & condition
                            <input type="checkbox" required />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="col-xl-6">
                          <input
                            type="submit"
                            value="Send Message"
                            className="nft-form-button"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="nft-form-img text-end">
                    <img
                      src={
                        require(`../../../assets/img/nft-contact-form-img.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
