import React from "react";

function Hero() {
  return (
    <div className="breadcrumb-area author-breadcrumb-bg d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-6">
            <div className="author-breadcrumb-left d-flex align-items-center">
              <div className="author-profile-img">
                <img
                  src={require(`../../../assets/img/single-author.jpg`).default}
                  alt=""
                />
              </div>
              <div className="author-profile-info">
                <h4 className="author-name">Amber Case</h4>
                <span>@amber_case</span>
                <ul className="author-follower-box">
                  <li>
                    <span className="follower-number">886K</span> Followers
                  </li>
                  <li>
                    <span className="follower-number">69K</span> Following
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="author-breadcrumb-left text-end">
              <button type="button" className="boxed-btn">
                Follow
              </button>
              <span className="author-profile-click-menu">
                <i className="fas fa-ellipsis-h"></i>
              </span>
              <p className="author-address">
                21,159 Photos Portland, Oregon , USA Joined 2018
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
