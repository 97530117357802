import React from "react";
import useToggle from "../../../hooks/useToggle";
import CountDownTopBar from "../../Helpers/CountDownTopBar";
import Navigations from "../Navigations";

function HeaderHomeOne({ className, action }) {
  const [toggle, setToggle] = useToggle(false);
  return (
    <header className={`nft-header-area ${className || ""}`}>
      <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-3 col-sm-6 d-flex text-center align-items-center">
              <p className="welcome-text">
                Welcome To Our
                <span className="nft-highlight-color">Beatplan</span>
              </p>
            </div>
            <div className="col-xl-7 col-md-9 col-sm-6 d-flex justify-content-end align-items-center">
              <CountDownTopBar lastDate="2023-03-04 4:00:00" />
              <div className="nft-social-icons">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="#">
                  <i className="fab fa-google"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
              <div className="nft-site-lang">
                <a href="#" className="">
                  English
                </a>
                <span>
                  <i className="fas fa-angle-down"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-md-3 d-flex justify-content-between align-items-center mb-sm-20">
              <div className="logo">
                <a href="/">
                  <img
                    src={require(`../../../assets/img/logo.png`).default}
                    alt=""
                  />
                </a>
              </div>
              <div className="d-xl-none">
                <svg
                  onClick={action}
                  style={{ fill: "currentColor", color: "white" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" />
                </svg>
              </div>
            </div>
            <div className="col-md-3 col-xl-6 d-md-none d-xl-block">
              <Navigations />
            </div>
            <div className="col-xl-4 col-md-9  d-flex align-items-center justify-content-end">
              <div className="nft-site-color-mode">
                <a href="#" className="dark-mode">
                  <img
                    src={require(`../../../assets/img/mode-dark.png`).default}
                    alt=""
                  />
                </a>
                <a href="index-day-mode.html" className="light-mode">
                  <img
                    src={require(`../../../assets/img/mode-light.png`).default}
                    alt=""
                  />
                </a>
              </div>

              <div className="nft-search-bar">
                <input type="search" name="" id="" placeholder="Search" />
              </div>

              <div className="nft-user">
                <span className="nft-user">
                  <img
                    src={require(`../../../assets/img/user.png`).default}
                    alt=""
                  />
                </span>
                <span className="nft-user-menu" onClick={setToggle.toggle}>
                  <img
                    src={require(`../../../assets/img/user-menu.png`).default}
                    alt=""
                  />
                </span>
                <div
                  onClick={setToggle.toggle}
                  className={`nft-user-menu-away ${toggle ? "active" : ""}`}
                ></div>
                <div
                  id="nft-user-menu-wrapper"
                  className={`nft-user-menu-wrapper ${toggle ? "active" : ""}`}
                >
                  <h6 className="nft-user-name">Welcome John!</h6>
                  <a href="/dashboard-settings?query=Profile">
                    <i className="far fa-user"></i>
                    <span>Profile</span>
                  </a>
                  <a href="/dashboard-settings?query=Links">
                    <i className="fas fa-external-link-alt"></i>
                    <span>Links</span>
                  </a>
                  <a href="/dashboard-settings?query=Notification">
                    <i className="far fa-bell"></i>
                    <span>Notifications</span>
                  </a>
                  <a href="/dashboard-settings?query=Others">
                    <i className="fas fa-tag"></i>
                    <span>Offers</span>
                  </a>
                  <a href="/dashboard-settings?query=Appearance">
                    <i className="fas fa-braille"></i>
                    <span>Appearance</span>
                  </a>
                  <a href="/dashboard-settings?query=Support">
                    <i className="fas fa-headphones"></i>
                    <span>Support</span>
                  </a>
                  <a href="#">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Log Out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderHomeOne;
