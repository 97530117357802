import React, { useState } from "react";
import ColumCardStyleOne from "../../Cards/ColumCardStyleOne";

function ExploreProduct({ datas, className }) {
  const filter = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Arts",
    },
    {
      id: 3,
      name: "Cards",
    },
    {
      id: 4,
      name: "Collectibles",
    },
    {
      id: 5,
      name: "Photos",
    },
  ];
  const [tab, setTab] = useState(filter[0].id);
  const tabHandler = (value) => setTab(value);
  return (
    <section
      className={`explore-product-tab-area off-black-bg ${className || ""}`}
    >
      <div className="container">
        <div className="row">
          <div
            className="product-tab mb-50 text-sm-center d-flex justify-content-between align-items-center mb-50wow animated fadeInUp"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div className="tab">
              {filter &&
                filter.length > 0 &&
                filter.map((tabItem) => (
                  <button
                    onClick={() => tabHandler(tabItem.id)}
                    type="button"
                    className={`tablinks  ${
                      tab === tabItem.id ? "active" : ""
                    }`}
                    id="defaultOpen"
                  >
                    {tabItem.name}
                  </button>
                ))}
            </div>
          </div>
        </div>
        {filter &&
          filter.length > 0 &&
          filter.map((tabItem) => (
            <div
              key={Math.random()}
              id="all-product"
              className="tabcontent all"
              style={{ display: tabItem.id === tab ? "block" : "none" }}
            >
              <div className="row">
                {datas &&
                  datas.length > 0 &&
                  datas
                    .slice(0, 8)
                    .map((postItem) => <ColumCardStyleOne datas={postItem} />)}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default ExploreProduct;
