import React from "react";
import ColumCardStyleOne from "../../Cards/ColumCardStyleOne";

function MainContent({ datas }) {
  return (
    <section className="live-bidding-area black-bg">
      <div className="container">
        <div className="row">
          {datas &&
            datas.length > 0 &&
            datas.slice(0, 4).map((item) => (
              <div key={Math.random()} className="col-xl-3 col-md-6">
                <ColumCardStyleOne datas={item} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default MainContent;
